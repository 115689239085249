import React from "react";

const SigninPage = () => {
  return (
    <>
      <h1>Hello signin</h1>
    </>
  );
};

export default SigninPage;
