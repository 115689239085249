export const homeObjOne = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Design. Develop. Deploy.",
  headline: "About Us!",
  description:
    "Stuck with a business challenge? We can help! Our expert developers leverage the power of Java for robust backend functionality and React to create user-friendly, interactive interfaces.  This powerful combination allows us to craft custom web applications that solve your specific problems and streamline your operations.",
  buttonLabel: "Contact us",
  navigate: "contact",
  imgStart: false,
  img: require("../../assets/images/about.png"),
  alt: "img",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "contact",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Make it easy",
  headline: "Contact us",
  description:
    "Get access to our excelusive app that allows you to send unlimited transactions without getting charged any fees.",
  buttonLabel: "Get started",
  imgStart: true,
  img: require("../../assets/images/contact-img.png"),
  alt: "img",
  dark: false,
  primary: false,
  darkText: true,
};
