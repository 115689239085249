import React from "react";
import Icon1 from "../../assets/images/svg-1.svg";
import Icon2 from "../../assets/images/svg-4.svg";
import Icon3 from "../../assets/images/svg-2.svg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

const Services = () => {
  return (
    <>
      <ServicesContainer id="services">
        <ServicesH1>Our Services</ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon src={Icon1} />
            <ServicesH2>Custom Web Application</ServicesH2>
            <ServicesP>
              We translate your unique business needs into custom web
              applications that deliver results.
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={Icon2} />
            <ServicesH2>User-Centered Design</ServicesH2>
            <ServicesP>
              We design user-friendly interfaces with intuitive navigation,
              ensuring optimal user experience on any device.
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={Icon3} />
            <ServicesH2>Robust Backend Solutions</ServicesH2>
            <ServicesP>
              Our Java expertise ensure secure, scalable and efficient backend
              functionality that deives your application.
            </ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Services;
